<template>
  <div class="menu-edit-page w-100">
    <basic-subheader title="Tạo bộ câu hỏi">
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          v-if="showBtnActive"
          class="btn btn-primary ml-3"
          type="button"
          @click="showPopupChangeStatus(1)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Active
        </b-button>
        <b-button
          v-else
          class="btn btn-danger ml-3"
          type="button"
          @click="showPopupChangeStatus(0)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          @click="createQuestionGroup"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-overlay :show="loading">
        <FormQuestion
          ref="form"
          :id="id"
          :data="detail"
          is-update
          @submit="submit"
        />
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  GET_QUESTION_GROUP_DETAIL,
  RESET_STATE_SURVEY,
  CHANGE_STATUS,
  EDIT_QUESTION_GROUP,
} from '@/core/services/store/survey.module';
const { mapActions, mapMutations } = createNamespacedHelpers('survey');
export default {
  name: 'SurveyQuizeEdit',
  components: {
    FormQuestion: () => import('./components/Form.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
    };
  },
  created() {
    this.getQuestionGroup();
  },
  beforeDestroy() {
    this.RESET_STATE_SURVEY();
  },
  computed: {
    showBtnActive() {
      if ([0, 2].includes(this.detail?.status)) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      GET_QUESTION_GROUP_DETAIL,
      CHANGE_STATUS,
      EDIT_QUESTION_GROUP,
    }),
    ...mapMutations({ RESET_STATE_SURVEY }),
    returnPage() {
      this.$router.go(-1);
    },
    createQuestionGroup() {
      this.$refs.form.handleValidate();
    },
    async submit(params) {
      this.loading = true;
      const { error } = await this.EDIT_QUESTION_GROUP(params);
      if (error) {
        this.loading = false;
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
      this.returnPage();
    },
    async getQuestionGroup() {
      this.loading = true;
      const { error, data } = await this.GET_QUESTION_GROUP_DETAIL(this.id);
      if (!error) {
        this.detail = data;
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loading = false;
    },
    showPopupChangeStatus(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(state);
          }
        }.bind(this),
      );
    },
    async changeStatus(state) {
      this.loading = true;
      const params = {
        id: this.id,
        state,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.getQuestionGroup();
      } else {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-danger {
  background-color: #ff5756 !important;
  border-color: #ff5756 !important;
  color: #fff !important;
}
</style>
